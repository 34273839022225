import { AfterViewInit, Component, ElementRef } from '@angular/core';
import { UserService } from '@app/service';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})

export class AppComponent implements AfterViewInit {

	constructor(public userService: UserService,
	            translate: TranslateService,
	            private elementRef: ElementRef) {
		translate.addLangs(['pt-br']);
		translate.setDefaultLang('pt-br');
		translate.use('pt-br');
	}

	public ngAfterViewInit() {
		this.elementRef.nativeElement.removeAttribute('ng-version');
	}

}
