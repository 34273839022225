import { Deserializable } from './deserializable';
import { Operation } from './operation';

export class Segment implements Deserializable {
    id: number;
    name: string;
    description: string;
    customerId: number;

    operations: Operation[];
    speechEnvironmentId: any;
	speechEnvironmentName: string;
    speechSegmentId: any;

	speechIntegrationActive: boolean;

	selected? : boolean;

	active: boolean = true;

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }

}
