import { BaseServiceClass } from './base-service';
import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RefreshAccessTokenResponse, User, UserStatusEnum } from '@app/model';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class UserService extends BaseServiceClass<any> {

	public jwtToken: string = '';
	public refreshToken: string;
	public deviceKey: string;
	public currentUser: User;
	public returnUrl: string = '/users';

	public userPermissions: any[];

	public currentJobInfo: {fieldOfWork: string | null, jobRole: string | null} = {fieldOfWork: null, jobRole: null};

	constructor(private http: HttpClient, private router: Router, @Inject(DOCUMENT) private document: Document) {
		super(http, '/api/v1/users');
	}

	public logout(): void {
		let clonedToken = this.jwtToken;
		localStorage.clear();
		this.document.location.href = environment.apps['id'] + `/logout?token=${clonedToken}`;
	}

	public getCurrentUser(): Observable<any> {
		return this.http.get(`${this.entity_url}/current`);
	}

	public getCurrentUserPermissions(): Observable<any> {
		return this.http.get(`${this.entity_url}/current/roles`);
	}

	public invite(obj: any): Observable<any> {
		return this.httpService.post(`${this.entity_url}/invite`, obj);
	}

	public addUserToRole(userId: any, roleId: any): Observable<any> {
		///api/v1/users/{id}/roles/{roleId}
		return this.http.put(`${this.entity_url}/${userId}/roles/${roleId}`, {});
	}

	public getUsersByRole(roleId: string): Observable<any> {
		return this.http.get(`${this.entity_url}?roleIdIn=${roleId}`);
	}

	public defineAsAdmin(id: string, organizationId: string, adminValue: boolean): Observable<any> {
		return this.http.patch(`${this.entity_url}/${id}/organization/${organizationId}/privileges`, {isAdmin: adminValue});
	}

	public defineAsInternal(id: string, organizationId: string, internalValue: boolean): Observable<any> {
		return this.http.patch(`${this.entity_url}/${id}/organization/${organizationId}/privileges`, {isInternal: internalValue});
	}

	public removeUserFromOrganization(id: string, organizationId: string): Observable<any> {
		return this.http.delete(`${this.entity_url}/${id}/organizations/${organizationId}`);
	}

	public removeUserFromRole(id: string, roleId: string): Observable<any> {
		return this.http.delete(`${this.entity_url}/${id}/roles/${roleId}`);
	}

	public editJobInfo(jobInfo: {fieldOfWork: string, jobRole: string}) {
		return this.http.put(`${this.entity_url}/current/organizations`, jobInfo);
	}

	public redefinePassword(id: any): Observable<any> {
		return this.http.patch(`${this.entity_url}/${id}/password`, {});
	}

	public disableMfaUser(userId: string): Observable<any> {
		return this.http.patch(`${this.entity_url}/${userId}/mfa/disable`, {});
	}

	public resetAndOptionalUserMfa(userId: string): Observable<any> {
		return this.http.patch(`${this.entity_url}/${userId}/mfa/optional`, {});
	}

	public resendInvite(userId: string): Observable<any> {
		return this.http.patch(`${this.entity_url}/${userId}/invite`, {});
	}

	public patchUserStatus(id: any, status: string): Observable<any> {
		return this.http.patch(`${this.entity_url}/${id}/status/${status}`, {});
	}

	public refreshAccessToken(refreshToken?: string): Observable<RefreshAccessTokenResponse> {
		let body = {
			refreshToken: refreshToken ?? this.refreshToken,
			deviceKey: this.deviceKey
		}

		return this.http.post<RefreshAccessTokenResponse>(`${this.entity_url}/refreshAccessToken`, body);
	}

	public updateTokens(jwtToken: string, refreshToken: string, deviceKey: string): void {
		this.jwtToken = jwtToken;
		this.refreshToken = refreshToken;
		this.deviceKey = deviceKey;

		localStorage.setItem('jwtToken', this.jwtToken);
		localStorage.setItem('refreshToken', this.refreshToken);
		localStorage.setItem('deviceKey', this.deviceKey);
	}
}
