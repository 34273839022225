<ngb-accordion class="mb-5" #acc="ngbAccordion">
	<ng-container *ngFor="let productPermissions of productsPermissions; index as i;">
		<ngb-panel *ngIf="products[productPermissions[0][0].product] != 'Speech'">
			<ng-template ngbPanelHeader let-opened="opened">
				<div class="accordion-header">
					<button ngbPanelToggle class="btn btn-link accordion-header-button">
						Permissões no {{ products[productPermissions[0][0].product] }}
					</button>

					<button class="btn btn-link accordion-header-button px-3" ngbPanelToggle>
						<i class="fas fa-chevron-down" *ngIf="!opened"></i>
						<i class="fas fa-chevron-up" *ngIf="opened"></i>
					</button>
				</div>
			</ng-template>
			<ng-template ngbPanelContent>
				<div class="permission-item py-3 d-flex justify-content-between align-items-center" *ngFor="let groupPermissions of productPermissions; index as j; trackBy:trackByIndex;">
					<div class="" style="max-width: 50%">
						<span class="" style="font-weight: bold; font-size: 16px;">{{ 'permissionGroups.' + groupPermissions[0].group | translate }}</span>
						<p style="font-size: 12px; color: #727272;">{{ 'permissionGroupDescription.' + groupPermissions[0].group | translate }}</p>
					</div>

					<evo-select style="width: 330px;">
						<ng-select class="ng-select-search"
						           (ngModelChange)="onPermissionsChanged($event)"
						           [(ngModel)]="selectedPermissions[i + '_' + j]"
						           [ngModelOptions]="{standalone: true}"
						           [appendTo]="(j + 1 == productPermissions.length) ? 'body' : ''"
						           [items]="groupPermissions"
						           [clearable]="false"
						           [multiple]="true"
						           [disabled]="!canEdit"
						           [dropdownPosition]="(j + 1 == productPermissions.length) ? 'top' : 'bottom'"
						           [closeOnSelect]="false"
								   placeholder="Selecione as permissões"
						           bindLabel="description">
							<ng-template ng-multi-label-tmp let-selecteditems="items" let-clear="clear">
								<div class="ng-value d-flex" style="max-width: 230px !important" container="body"
								     placement="top" [ngbTooltip]="getItemLabelByValue(groupPermissions, item)"
								     *ngFor="let item of selecteditems | slice:0:1">
									<span class="ng-value-icon left" (click)="clear(item)" aria-hidden="true">×</span>
									<span class="ng-value-label item-label" style="font-size: 0.9em">{{ getItemLabelByValue(groupPermissions, item) }}</span>
								</div>
								<div class="ng-value" *ngIf="selecteditems.length > 1">
									<span class="ng-value-label">+{{selecteditems.length - 1}}</span>
								</div>
							</ng-template>
						</ng-select>
					</evo-select>
				</div>
			</ng-template>
		</ngb-panel>
	</ng-container>
</ngb-accordion>
